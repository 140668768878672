import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shadows: ["none"],
  palette: {
    secondary: {
      light: "#D6E5F7",
      main: "#6E6EF5",
    },
    primary: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "AeonikPro-Regular",
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: () => ({
          borderRadius: 0,
        }),
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            ":hover": {
              backgroundColor: "transparent",
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "h4" },
          style: {
            textAlign: "center",
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          border: "2px solid black",
          borderRadius: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 9,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          margin: 0,
          marginTop: 12,
        },
      },
    },
  },
});

export default theme;
